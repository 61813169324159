import { filter } from "rxjs";
import * as Animations from "./animations";
import { Menu, MenuEvent, MenuEventType } from "./components/menu";

(async () => {
  const menuManager = {};
  /**
   * Apres le chargement de la page
   */
  function onload() {
    size();
    loadImages();
    initMenu();
  }
  /**
   * Dimensionne les boites
   */
  function size() {
    // redimmensionne le contenu en fonction de la taille de l'entete (pourquoi elle est pas fixe ? on en sait rien)
    const header = document.getElementsByTagName("header")[0];
    const wrapper = document.getElementsByClassName("content-wrapper")[0];
    (wrapper as HTMLElement).style.paddingTop = `${header.offsetHeight}px`;
  }
  /**
   * Décale le chargement des images non critique
   * après le chargement complét de la page
   */
  function loadImages() {
    const images = document.getElementsByTagName("img");
    Array.from(images).forEach((img) => {
      if (img.dataset.src) {
        img.src = img.dataset.src;
      }
    });

    // load background
    const bgimg = document.querySelectorAll("[data-bgsrc");
    bgimg.forEach((element) => {
      const el = element as HTMLElement;
      console.log(el.dataset);
      if (el.dataset.bgsrc) {
        el.style.backgroundImage = `url(${el.dataset.bgsrc})`; //img.dataset.src;
      }
    });
  }
  /**
   *
   */
  function initMenu() {
    const mainMenu = document.getElementById("main-nav-menu");
    const mainMenuMode = mainMenu?.dataset["mode"];
    if (mainMenuMode == "ext") return;
    document.getElementById("hamburger-btn")?.addEventListener("click", () => {
      if (!mainMenu) return;
      mainMenu.style.transform = "translateX(0%)";
    });

    const menus = document.getElementsByClassName("menu");
    Array.from(menus).forEach((menu) => {
      // link
      // Init Menu
      const menuManager = Menu(menu as HTMLElement);
      menuManager.on //
        .pipe(filter((e) => e.type == MenuEventType.CLICK)) //
        .subscribe((event: MenuEvent) => {
          if (mainMenu && window.innerWidth <= 600) mainMenu.style.transform = "translateX(-100%)";
          // 📌 S'il s'agit d'un lien interne (une ancre)
          if (event.element?.href && (event.element?.href ?? "").lastIndexOf("#") > 0) {
            const href = event.element.href;
            const anchor = href.substring(href.lastIndexOf("#") + 1, href.length);
            Animations.scrollToAnchor(anchor);
          }
        });
    });
  }
  if (window.addEventListener) window.addEventListener("DOMContentLoaded", onload);
})();
