import { BehaviorSubject, Observable } from "rxjs";

export enum MenuEventType {
  CLICK = "click",
  DISABLED = "selected",
  ENABLED = "unselected",
  NULL = "null",
}

export type MenuEvent = {
  event: MouseEvent | null;
  type: MenuEventType;
  element: HTMLAnchorElement | null;
};

export type MenuManager = {
  on: Observable<MenuEvent>;
};

export type MenuItemManager = {
  on: Observable<MenuEvent>;
};

/**
 *
 * Gere la classe CSS des elèments
 * Permet de connecter les events du menu
 *
 * @param element
 * @returns
 */
export function Menu(element: HTMLElement): MenuManager {
  /**
   *
   */
  const navHistory: HTMLAnchorElement[] = [];
  let historyIndex = 0;

  /**
   *
   */
  const eventSubject = new BehaviorSubject<MenuEvent>({ event: null, type: MenuEventType.NULL, element: null });
  const on$ = eventSubject.asObservable();

  /**
   *
   */
  function initLinks() {
    const linksList = Array.from(element.getElementsByTagName("a"));
    const actualLink = window.location.href;
    // Premier lien selectionné
    setSelectedLink(linksList[0]);

    linksList.forEach((link) => {
      // Si lien actuel == lien
      if (link.href === actualLink) setSelectedLink(link);
      // Propage les événements des items
      const menuItemManager = MenuItem(link);

      // Propagation des évènements vers l'extérieur
      menuItemManager.on.subscribe((event) => {
        if (event.type === MenuEventType.CLICK) {
          setSelectedLink(event.element as HTMLAnchorElement);
        }
        eventSubject.next(event);
      });
    });
  }
  /**
   *
   * @param link
   */
  function setSelectedLink(link: HTMLAnchorElement) {
    if (historyIndex > 0) {
      navHistory[historyIndex - 1].classList.remove("active");
      eventSubject.next({ event: null, type: MenuEventType.DISABLED, element: navHistory[historyIndex - 1] });
    }
    link.classList.add("active");
    navHistory.push(link);
    historyIndex++;
    eventSubject.next({ event: null, type: MenuEventType.ENABLED, element: link });
  }

  initLinks();

  return { on: on$ };
}

/**
 *
 * @returns
 */
export function MenuItem(element: HTMLAnchorElement): MenuItemManager {
  const eventSubject = new BehaviorSubject<MenuEvent>({ event: null, type: MenuEventType.NULL, element: null });
  const on$ = eventSubject.asObservable();
  /**
   *
   */
  function init() {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      eventSubject.next({ event: event, type: MenuEventType.CLICK, element });
    });
  }
  /**
   *
   */
  init();

  return { on: on$ };
}
